import React from "react";
import slugify from "slugify";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import { Title, Flex, Text, Grid, Box } from "../utils/system";
import Header from "../components/Header";

const Insights = ({ data }) => {
  const insights = data.allSanityBlog.nodes;
  return (
    <Layout>
      <Header title={"Insights"} subtitle={"Research & Development"} />
      <Flex mb={6}>
        <Grid
          gridGap={4}
          gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
          width="90%"
        >
          {insights &&
            insights.map((item, key) => (
              <Link
                to={`/insights/${slugify(item.title, { lower: true })}`}
                key={key}
              >
                <Box>
                  <GatsbyImage
                    image={item.image.asset.gatsbyImageData}
                    alt={item.title}
                  />
                  <Title mb={1} fontSize={[4, 4]}>
                    {item.title}
                  </Title>
                  <Text color="blue">{item._createdAt}</Text>
                </Box>
              </Link>
            ))}
        </Grid>
      </Flex>
    </Layout>
  );
};

export default Insights;

export const pageQuery = graphql`
  query Insights {
    allSanityBlog {
      nodes {
        _createdAt(formatString: "Do MMMM YYYY")
        title
        image {
          asset {
            gatsbyImageData(aspectRatio: 1.5)
          }
        }
        _rawContent
      }
    }
  }
`;
